<template>
    <div class="log-filter">
        <transition name="fade">
            <div class="sliderBg" @click="$emit('update:ctrlFilterPop', false)"></div>
        </transition>
        <transition name="slider-customer">
            <div class="sliderBody">
                <div class="filter-key">按模板筛选</div>
                <div class="name-group">
                    <span class="temp-cell" :class="thatQueryParam.paperId === item.paperId ? 'active' : ''" v-for="(item, index) in lookNameList" :key="index" @click.stop="thatQueryParam.paperId = item.paperId">{{item.name}}</span>
                </div>
                <div class="filter-key">按发送人筛选</div>
                <div class="add-ctId">
                    <div class="ctId-list" v-if="thatQueryParam.ctId">
                        <i class="icon iconfont icon-my"></i>
                        <div class="send_name">{{getRealName(thatQueryParam.ctId)}}</div>
                    </div>
                    <i class="iconfont icon-add-round" @click.stop="$emit('update:ctrlCtIdPop', true)"></i>
                </div>
                <div class="start-time">
                    <span class="_span">开始时间</span>
                    <input type="date" id="startT" v-model="thatQueryParam.start" />
                </div>
                <div class="end-time">
                    <span class="_span">结束时间</span>
                    <input type="date" id="endT" v-model="thatQueryParam.end" />
                </div>
                <div class="handler-btn">
                    <van-button @click.stop="exeFilter(1)" class="cus-btn" type="default">取消</van-button>
                    <van-button @click.stop="exeFilter(2)" class="cus-btn" type="danger">确定</van-button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        showFilterPop: {
            type: Boolean
        },
        lookNameList: {
            type: Array
        },
        queryParam: {
            type: Object
        },
        allUserList: {
            type: Array
        }
    },
    components: {},
    data() {
        return {
            thatQueryParam: {}
        }
    },
    computed: {},
    watch: {
        'queryParam': {
            handler(newV, oldV) {
                this.thatQueryParam = JSON.parse(JSON.stringify(newV))
            },
            deep: true
        }
    },
    created() {
        this.thatQueryParam = JSON.parse(JSON.stringify(this.queryParam))
    },
    mounted() { },
    methods: {
        getRealName(ctId) {
            try {
                if (ctId) {
                    let _p = this.allUserList.filter(v => v.ctId === ctId)
                    return _p[0].realName
                } else {
                    return ''
                }
            } catch (err) {
                console.log(err)
                return ''
            }
        },
        exeFilter(n) {
            // n 1:重置, 2:确定筛选
            if (n === 1) {
                if (this.thatQueryParam.ctId) this.thatQueryParam.ctId = ''
                if (this.thatQueryParam.start) this.thatQueryParam.start = ''
                if (this.thatQueryParam.end) this.thatQueryParam.end = ''
                if (this.thatQueryParam.paperId) this.thatQueryParam.paperId = ''
            }
            this.$emit('watchFilter', this.thatQueryParam, n)
        }
    },
    beforeDestroy() { }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
